<script>
    import { config } from '../config.js'
    import { util } from '../util.js'
    import axios from 'axios'

    export default {
        watch: {
            chunks : {
                handler( n ) {
                    if (n.length > 0) {
                        this.upload();
                    }
                },
                deep : true
            }
        },

        data() {
            return {
                file: null,
                chunks_cnt: 0,      // celkovy pocet chunks pro vypocet %
                chunks_uploaded : 0, //pocet uploadnutych chunks
                chunks: [],
                uploaded: 0,

                chunk_size: 100,
                done_error : false,
                done_msg : '',
            };
        },

        computed: {
            progress() {
                if ( this.file === null ) return 0;
                let ret = Math.floor((this.chunks_uploaded * 100) / this.chunks_cnt);
                //console.log("ret:" , ret, " up:", this.uploaded, " fs:", this.file.size);
                return ret
            },
            /*
            formData() {
                let formData = new FormData;
                formData.set('is_last', this.chunks.length === 1);
                formData.set('file', this.chunks[0], `${this.file.name}.part`);
                return formData;
            },
            */
            axios_cfg() {
                return {
                    method: 'POST',
                    //data: this.formData,
					withCredentials: true,
                    data: this.chunks[0],
                    url: config.API_URL + '/api/firmware?n='+this.chunks_uploaded+'&c='+this.chunks_cnt,
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                    onUploadProgress: ev => {
                        this.uploaded += ev.loaded
                        this.chunks_uploaded++
                    }
                };
            }
        },

        methods: {
            select(ev) {
                this.file = ev.target.files.item(0);
                //console.log("this.file:" , this.file);
                this.createChunks();
            },
            upload() {
                axios(this.axios_cfg).then( resp => {
                    var data = resp.data
                    console.log("data:" , data);
                    this.chunks.shift()
                    if ( this.chunks.length == 0 ) {
                        let msg = "Firmware uploaded successfully. Press reboot to install."
                        util.ShowToastOk( msg )
                        this.done_msg = msg
                    } else if ( resp.data.error !== undefined  ) {
                        let msg = "Upload error: " + resp.data.error
                        util.ShowToastErr(msg)
                        this.done_error = true
                        this.done_msg = msg
                        this.chunks = []
                    }
                }).catch( error => {
                    let msg = "Network error."
                    util.ShowToastErr( msg )
                    this.done_error = true
                    this.done_msg = msg
                    this.chunks = []
                    console.log("error:" , error);
                });
            },
            createChunks() {
                let size = this.chunk_size, chunks = Math.ceil(this.file.size / size);

                this.chunks = []
                for (let i = 0; i < chunks; i++) {
                    this.chunks.push(this.file.slice(
                        i * size, Math.min(i * size + size, this.file.size), this.file.type
                    ));
                }
                this.chunks_cnt = this.chunks.length
                this.chunks_uploaded = 0
                this.done_error = false
                this.done_msg = ''
            },
            // spocitej velikost chunk pro upload firmware
            getChunkLength() {
                let bin_data = new Uint8Array([66,11,12,23,14,15,65]); 
                let blob = new Blob([bin_data], {type: 'application/octet-stream'});
                //let blob = new Blob(['x'], {type: 'text/plain'});
                //let frm_data = new FormData;
                //frm_data.set('file', blob, `filename.part`);
                let cfg = {
                    method: 'POST',
					withCredentials: true,
                    data: blob,
                    url: config.API_URL + '/api/firmware?init=1',
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    }
                }
                axios(cfg).then( resp => {
                    let d = resp.data.data
                    this.chunk_size = parseInt(d.buff_size - (d.head_size*1.8));
                    //console.log("resp:" , d);
                    console.log("chunk_size:" , this.chunk_size);
                }).catch( () => {
                    //console.log("error:" , error);
                });
            }
        },
		mounted: function() {
            this.getChunkLength()
		}

    }
</script>

<template>
    <div>
        <div v-show="done_msg !== ''" class="tag is-medium is-grey"
            :class="[ done_error ? 'is-danger' : 'is-success' ]"
             >
             {{ done_msg }}
        </div>
		<br/>
		<br/>
        <progress class="progress" :value="progress" max="100"></progress>
        <br/>
		<div class="file is-info is-right">
			<label class="file-label">
				<input class="file-input" type="file" @change="select">
				<span class="file-cta">
					<span class="file-icon">
						<i class="fas fa-upload"></i>
					</span>
					<span class="file-label">
						Choose a file…
					</span>
				</span>
			</label>
		</div>
        <br/>
    </div>
</template>

