
export let config = {
    ROOT : '/',
    API_URL : '',
    AXIOS_PARAMS : {},

    DEVEL_DATA : null,

    IsDevel() {
        return this.DEVEL_DATA != null
    },
}

