
import {reactive} from 'vue';

export const store = reactive({ 
    login : {
        logged_in : false,
        username : '???',
    },
    info : {},

    error_app : '',

    IsUiModeDebug : function() {
        const uim = store.info?.ui_mode
        return uim == 99
    },
    ErrorAppClear : function() {
        store.error_app = ''
    },
})

