// https://opensourcelibs.com/lib/bulma-toast
import { toast } from 'bulma-toast'
import axios from 'axios'
import { config } from './config.js'
import { store } from './store.js'

var _error_msgs = {
    "E_SETTINGS_IP_ERR" : "Error: Invalid IP address.",
}


//import { ToastProgrammatic as Toast } from 'buefy'

let api_routes = {
    "version" : "/api/version",
    "login" : "/api/login",
    "settings" : "/api/settings",
    "change-password" : "/api/change-password",
    "reboot" : "/api/reboot",
}

axios.interceptors.response.use(function (response) {
    store.error_app = ''
    return response;
}, function (error) {
    store.error_app = error
    console.log("error:" , error);
    return Promise.reject(error);
});

export const util = {

    GetErrorMsg( ss ) {
        const aa = ss.split(":");
        const msg = _error_msgs[aa[0].trim()]
        const ret = "Unknown error: " + ss
        if ( msg !== undefined && msg !== null ) {
            return msg
        }
        return ret
    },

    ShowToastOk( msg ) {
        console.log("OK msg:" , msg);
        toast({
            message: msg,
            type: 'is-success',
            duration: 2000,
            position: "top-center",
        })
        /*
        Toast.open({
            message: msg,
            type: 'is-success',
            position: 'is-top',
            duration: 2000,
        })
        */
    },

    ShowToastErr( msg ) {
        console.log("ERROR msg:" , msg)
        if ( typeof msg === 'object' ) {
            msg = msg?.message || 'Unknown error - check console'
        }
        toast({
            message: msg,
            type: 'is-danger',
            duration: 2000,
            position: "top-center",
        })
        /*
        Toast.open({
            message: msg.toString(),
            type: 'is-danger',
            position: 'is-top',
            duration: 3000,
        })
        */
    },
    IsDef( v ) {
        if ( v === undefined || v === null ) return false
        return true
    },

    ErrorRedirectTimer() {
        setTimeout(function() {window.location.href = config.ROOT; }, 5000);
    },

    ErrorRedirect( resp ) {
        resp.then((response) => {
            if ( response.status == 401 ) {
                util.ShowToastErr("Not authorised.")
                util.ErrorRedirectTimer()
            }
        }).catch(() => {
            util.ShowToastErr("Error call API.")
            util.ErrorRedirectTimer()
        })
    },
    
    AxiosGet( url ) {
        var resp = axios.get( url, config.AXIOS_PARAMS )
        return resp
    },

    AxiosPost( url, pars ) {
        var resp = axios.post( url, pars, config.AXIOS_PARAMS )
        return resp
    },

    AxiosGetByRouteName( name, pars = "" ) {
        if ( api_routes[name] === undefined ) {
            console.log("Cannot find api_route for name: ", name)
            return false
        }
        let ss = config.API_URL + api_routes[name] + pars
        //return axios.get( ss, config.AXIOS_PARAMS )
        return util.AxiosGet( ss )
    },

    AxiosPostByRtName( name, pars  ) {
        if ( api_routes[name] === undefined ) {
            console.log("Cannot find api_route for name: ", name)
            return false
        }
        let ss = config.API_URL + api_routes[name]
        return util.AxiosPost( ss, pars )
    },

}

