
import { config } from './config.js'
import { util } from './util.js'
import { store } from './store.js'

export const libapi = {
    ERROR : "error",
    OK : "ok",


    IsEEError( resp ) {
        const err = resp?.error || null
        if ( err !== null ) {
            var idx = err.trim().indexOf("EE_")
            if ( idx === 0 ) return true
        }
        return false
    },

    ShowEEError( err ) {
        store.error_app = err
        // util.ShowToastErr(err)
    },


    HandleEEError( resp ) {
        if ( libapi.IsEEError(resp.error) ) {
            libapi.ShowEEError(resp.error)
            return true
        }
        return false
    },

    globalErrorHandler( err ) {
        store.error_app = err
        //util.ShowToastErr(err)
    },

    appErrorHandle( response ) {
        if ( response.data.error !== undefined  ) {
            util.ShowToastErr(util.GetErrorMsg(response.data.error))
        } else {
            util.ShowToastErr('response.data.error missing: ' + response.data)
        }
    },

    isRespOk( response ) {
        return util.IsDef(response.data.data)
    },

    handleResponse( response, opt = {}) {
        if ( this.isRespOk( response ) ) {
            if ( util.IsDef(opt.onOk) ) opt.onOk(response.data)
        } else {
            //pokud fce bere jen validacni errory tak je zajisteno, ze kdyz prijde jina chyba neco se zobrazi
            let el = response.data.error_list
            if ( util.IsDef(opt.onErrorValidate) && util.IsDef(el) ) opt.onErrorValidate(response.data)
            else if ( util.IsDef(opt.onError) ) opt.onError(response.data)
            else this.appErrorHandle(response)
        }
        if ( opt.onAlways !== undefined && opt.onAlways !== null ) opt.onAlways(response.data)
    },

    Login( user, passwd ) {
        return new Promise( (resolve) => {
            const post_data = { login : user, password : passwd }
            let api = config.API_URL + "/api/login"
            util.AxiosPost(api, post_data ).then((response) => {
                if ( this.isRespOk( response ) ) {
                    store.login.logged_in = true
                    store.login.username = user
                    resolve(libapi.OK)
                } else {
                    store.login.logged_in = false
                    store.login.username = "?"
                    resolve(libapi.ERROR)
                }
            }).catch(this.globalErrorHandler)
        })
    },


    Logout() {
        let api = config.API_URL + "/api/logout"
        util.AxiosGet(api).then(() => {
            store.login.logged_in = false
        }).catch(this.globalErrorHandler)
    },

    Info: function() {
        return new Promise( (resolve) => {
            let api = config.API_URL + "/api/info"
            util.AxiosGet(api).then((response) => {
                store.info = response.data.data
                resolve(response.data)
            }).catch(this.globalErrorHandler)
        })
    },

    Data: function() {
        return new Promise( (resolve) => {
            let api = config.API_URL + "/api/data"
            util.AxiosGet(api).then((response) => {
                resolve(response.data)
            }).catch(this.globalErrorHandler)
        })
    },

    Ctrl : function(params) {
        return new Promise( (resolve) => {
            let api = config.API_URL + "/api/ctrl"
            util.AxiosPost(api, params ).then((response) => {
                resolve(response.data)
            }).catch(this.globalErrorHandler)
        })
    }

    /*
    SaveSettings( data, opt ) {
        util.AxiosPostByRtName("settings",data).then((response) => {
            this.handleResponse( response, opt )
        }).catch(this.globalErrorHandler)
    },

    ChangePassword( data, opt ) {
        util.AxiosPostByRtName("change-password",data).then((response) => {
            this.handleResponse( response, opt )
        }).catch(this.globalErrorHandler)
    },

    Reboot( data, opt ) {
        util.AxiosPostByRtName("reboot",data).then((response) => {
            this.handleResponse( response, opt )
        }).catch(this.globalErrorHandler)
    },
    */

}

