<template>

    <div class="box">
        <!-- <div class="is-size-3">Measure</div> -->
        <!-- is-vcentered -->
		<div class="is-size-3">Basic information</div>
        <div class="columns is-hcentered">

            <div class="column is-6">
                <div class="box">
                    <table class="table is-striped is-fullwidth">
                        <tbody>
							<tr>
								<td class="has-text-left">PV1 input voltage</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_so_in_vo_1 }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">PV2 input voltage</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_so_in_vo_2 }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">PV1 input power</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_so_in_po_1 }}</td>
								<td class="has-text-left">W</td>
							</tr>
							<tr>
								<td class="has-text-left">PV2 input power</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_so_in_po_2 }}</td>
								<td class="has-text-left">W</td>
							</tr>
							<tr>
								<td class="has-text-left">Grid voltage R</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_ac_in_vo_r }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">Grid voltage S</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_ac_in_vo_s }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">Grid voltage T</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_ac_in_vo_t }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">Grid frequency</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_ac_in_freq }}</td>
								<td class="has-text-left">Hz</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output voltage R</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_ac_out_vo_r }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output voltage S</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_ac_out_vo_s }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output voltage T</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_ac_out_vo_t }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">Load level</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_po_pe }}</td>
								<td class="has-text-left">%</td>
							</tr>
							<tr>
								<td class="has-text-left">Charging current</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_bat_cu }}</td>
								<td class="has-text-left">A</td>
								<td class="has-text-left"></td><td></td><td></td>
							</tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <table class="table is-striped is-fullwidth">
                        <tbody>
							<tr>
								<td class="has-text-left">Battery voltage</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_bat_vo }}</td>
								<td class="has-text-left">V</td>
							</tr>
							<tr>
								<td class="has-text-left">Battery capacity</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_bat_ca }}</td>
								<td class="has-text-left">%</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output active power R</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_ac_po_r }}</td>
								<td class="has-text-left">W</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output active power S</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_ac_po_s }}</td>
								<td class="has-text-left">W</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output active power T</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_ac_po_t }}</td>
								<td class="has-text-left">W</td>
							</tr>
							<tr>
								<td class="has-text-left">Total AC output active power</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_to_ac_po }}</td>
								<td class="has-text-left">W</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output apparent power R</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_ap_po_r }}</td>
								<td class="has-text-left">VA</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output apparent power S</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_ap_po_s }}</td>
								<td class="has-text-left">VA</td>
							</tr>
							<tr>
								<td class="has-text-left">AC output apparent power T</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_ap_po_t }}</td>
								<td class="has-text-left">VA</td>
							</tr>
							<tr>
								<td class="has-text-left">Total AC output apparent power</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.ps_ac_out_to_ap_po }}</td>
								<td class="has-text-left">VA</td>
							</tr>
							<tr>
								<td class="has-text-left">Inner temperature</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_in_temp }}</td>
								<td class="has-text-left">&#8451;</td>
							</tr>
							<tr>
								<td class="has-text-left">Max component temperature</td>
								<td class="has-text-weight-bold has-text-right">{{ idata.gs_comp_max_temp }}</td>
								<td class="has-text-left">&#8451;</td>
							</tr>
							<tr>
								<td class="has-text-left">&nbsp;</td>
								<td class="has-text-weight-bold has-text-right"></td>
								<td class="has-text-left"></td>
								<td class="has-text-left"></td><td></td><td></td>
							</tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    </div>

</template>

<script>

// import { config } from '../config.js'
import { util } from '../util.js'

export default {
    name: 'AppInfo',
    data : function() {
        return {
			timer_delay : 5000,
            timer_on : true,
            idata : { }
        }
    },
    computed : {
    },
    methods: {
        GetData: function() {
			util.Get_iData( (resp) => {
				this.idata = resp.data
			})
        },
        RefreshData: function() {
			if ( this.timer_on ) {
				this.GetData()
				setTimeout(this.RefreshData, this.timer_delay);
			}
        },
		
    },
    mounted: function() {
		this.timer_on = true
        this.GetData();
		setTimeout(this.RefreshData, 1);
    },
	beforeUnmount: function() {
		this.timer_on = false
	},
}
</script>

