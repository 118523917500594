import UserLogin from './components/UserLogin.vue';
import AppAbout from './components/AppAbout.vue';
import AppInfo from './components/AppInfo.vue';
import AppDashboard from './components/AppDashboard.vue';
import AppSettings from './components/AppSettings.vue';
import { createWebHistory, createRouter } from "vue-router";
import { config } from './config.js'

const routes = [
    { path: config.ROOT+'/', name: 'Root', component: AppAbout },
    { path: config.ROOT+'dashboard', name: 'AppDashboard', component: AppDashboard },
    { path: config.ROOT+'info', name: 'AppInfo', component: AppInfo },
    { path: config.ROOT+'about', name: 'AppAbout', component: AppAbout },
    { path: config.ROOT+'login', name: 'UserLogin', component: UserLogin },
    { path: config.ROOT+'settings', name: 'AppSettings', component: AppSettings },
];


const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router;
