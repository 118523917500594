<template>

    <div class="box">
        <!-- <div class="is-size-3">Measure</div> -->
        <!-- is-vcentered -->
        <!--
		<div class="is-size-3">Přehled</div>
        -->
        <div class="columns is-hcentered">
            <div class="column is-4">

                <div class="box">
                    <table class="table" width="100%">
                        <tbody>
                            <tr>
                                <td class="my-td-vam has-text-weight-bold has-text-left"> 
                                    {{ info?.setup?.descr || '' }}
                                </td>
                                <td class="my-td-vam has-text-right">
                                    <p class="control">
                                        <button 
                                            class="button is-small is-active"
                                            @click="UserWatchingModeClick()">
                                            <i class="mdi mdi-tray-arrow-down mdi-24px" >{{ refreshCnt.toString().padStart(2,'0') }}</i>
                                        </button>
                                    </p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
				</div>

                <div class="box">

                    <table class="table" width="100%">
                        <tbody>
							
							<tr>
                                <td class="my-td-vam has-text-left p-0"> 
                                    <i class="mdi mdi-factory mdi-24px has-text-primary"></i>
                                </td>
                                <td class="my-td-vam has-text-left my-line-h1"> 
                                    Dnes vyrobeno
									<span v-if="IsUiModeDebug">
										<br/>
										<span class="is-size-7">DAY: {{ dataSe?.sum_last?.data?.sum?.DAY_W }} T: {{ dataSe?.sum_last?.data?.sum?.IN_TOT_KWH }} ({{ dataSe?.sum_last?.data?.sum?.IN_TOT_RAW_KWH }}) </span>
									</span>
                                </td>
                                <td class="my-td-vam has-text-weight-bold has-text-right is-size-4">
									<!-- {{ new Number(this.today_kwh || 0).toFixed(2) }} -->
									{{ (new Number(dataSe?.sum_last?.data?.sum?.DAY_W || 0) / 1000).toFixed(2) }}
                                </td>
                                <td class="my-td-vam has-text-left">kWH</td>
                            </tr>

                            <tr>
                                <td class="my-td-vam has-text-left p-0"> 
                                    <i class="mdi mdi-solar-power-variant-outline mdi-24px has-text-warning" @click="DebugClick()"></i>
                                </td>
                                <td class="my-td-vam has-text-left my-line-h1">
                                    Aktuální výroba
                                </td>
                                <td class="my-td-vam has-text-weight-bold has-text-right is-size-4">
                                    {{ new Number((dataSe?.sum?.data?.sum?.IN_S_W || 0 ) / 1000).toFixed(2) }}
                                </td>
                                <td class="my-td-vam has-text-left">kW</td>
							</tr>

                            <tr>
                                <td class="my-td-vam has-text-left p-0"> 
                                    <i class="mdi mdi-battery-50 mdi-24px has-text-info"></i>
                                </td>
                                <td class="my-td-vam has-text-left my-line-h1">
									Baterie
									<br/>
										<i v-show="Math.abs(batPwr) > 0"
											:class="[batPwr > 0 ? 'has-text-success mdi-arrow-right' : 'has-text-danger mdi-arrow-left']"
											class="mdi mdi-16px"
										></i>
									<span v-show="Math.abs(batPwr) > 0" >
										<span 
											class="is-size-7 has-text-right"
										>
											{{ new Number(batPwr/1000).toFixed(2) }}&nbsp;kW
										</span>
									</span>
									<span v-if="IsUiModeDebug">
										<br/>
										<span class="is-size-7">LO: {{ dataSe?.sum_last?.data?.sum?.BM_THR_LO_PBG }} HI: {{ dataSe?.sum_last?.data?.sum?.BM_THR_HI_PBG }} </span>
									</span>

                                </td>
								<td 
									:title="new Number(batPwr/1000).toFixed(2) + ' kW'"
									class="my-td-vam has-text-weight-bold has-text-right is-size-4"
								>
									{{ new Number(dataSe?.sum?.data?.sum?.BM_SOC_P || 0).toFixed(2) }}
                                </td>
                                <td class="my-td-vam has-text-left">%</td>
                            </tr>

                            <tr>
                                <td class="my-td-vam has-text-left p-0"> 
                                    <i class="mdi mdi-transmission-tower-export mdi-24px has-text-danger"></i>
                                </td>
                                <td class="my-td-vam has-text-left my-line-h1">
									Aktuální spotřeba
									<br/>
									<span class="is-size-7">BAK: {{ curLoadW[1] }} kW</span>
                                </td>
                                <td class="my-td-vam has-text-weight-bold has-text-right is-size-4">
                                    {{ curLoadW[0] }}
                                </td>
                                <td class="my-td-vam has-text-left">kW</td>
                            </tr>
                        </tbody>
                    </table>


				</div>

                <div class="box">
					<span class="tag mr-1 is-rounded is-small" title="Allow PV to charger battery">PVCH : {{ GetFlag('IN_F_PVCH') }}</span>
					<span class="tag mr-1 is-rounded is-small" title="Allow AC to charger battery">ACCH : {{ GetFlag('IN_F_ACCH') }}</span>
					<span class="tag mr-1 is-rounded is-small" title="Allow to feed-in the Grid">FIG : {{ GetFlag('IN_F_FIG') }}</span>
					<span class="tag mr-1 is-rounded is-small" title="Allow battery to discharge when PV is available">BDLPVA : {{ GetFlag('IN_F_BDLPVA') }}</span>
					<span class="tag mr-1 is-rounded is-small" title="Allow battery to discharge when PV is unavailable">BDLPVU : {{ GetFlag('IN_F_BDLPVU') }}</span>
					<span class="tag mr-1 is-rounded is-small" title="Allow battery to feed-in to the Grid when PV is available">BDGPVA : {{ GetFlag('IN_F_BDGPVA') }}</span>
					<span class="tag mr-1 is-rounded is-small" title="Allow battery to feed-in to the Grid when PV is unavailable">BDGPVU : {{ GetFlag('IN_F_BDGPVU') }}</span>

				</div>

                <div class="box">
                    <table class="table" width="100%">
                        <tbody>
							<tr><td class="has-text-left">Aktuální čas</td><td class="has-text-right">
									<span class="has-text-weight-bold">{{ timeNow[0] }}</span><br/> {{timeNow[1]}} 
							</td></tr>

                        </tbody>
                    </table>
				</div>


            </div>
            <div class="column is-8">

                <div class="box">
                    <div class="field has-addons">
                        <p class="control">
                            <button class="button" @click="ChValClick('g_tot_kwh')" :class="[ chart_value=='g_tot_kwh' ? 'is-primary' : '' ]">Výroba</button>
                        </p>
                        <p class="control">
                            <button class="button" @click="ChValClick('g_bat_proc')" :class="[ chart_value=='g_bat_proc' ? 'is-primary' : '' ]">Baterie</button>
                        </p>
                        <p class="control">
                            <button class="button" @click="ChValClick('e')" :class="[ chart_value=='e' ? 'is-primary' : '' ]">Elektroměr</button>
                        </p>
                    </div>
                </div>

                <div class="box">
                    <div class="field has-addons">
                        <p class="control">
                            <button class="button" @click="ChIntClick('HO')" :class="[ chart_interval=='HO' ? 'is-info' : '' ]">Den</button>
                        </p>
                        <p class="control">
                            <button class="button" @click="ChIntClick('DA')" :class="[ chart_interval=='DA' ? 'is-info' : '' ]">Měsíc</button>
                        </p>
                        <p class="control">
                            <button class="button" @click="ChIntClick('MO')" :class="[ chart_interval=='MO' ? 'is-info' : '' ]">Rok</button>
                        </p>
                        <p class="control">
                            <button class="button" @click="ChIntClick('YE')" :class="[ chart_interval=='YE' ? 'is-info' : '' ]">Minulé roky</button>
                        </p>
                    </div>
                    <div class="has-text-left">
                        <label class="checkbox">
                            <input type="checkbox"  v-model="chartLabelsEnabled" @change="ChLabelsEnabledChange"/>
                            Labels
                        </label>
                    </div>


                    <div ref="chart" :style="{ height: chartHeight + 'px' }"></div>
                    <!--
                    <div class="has-text-left is-size-7 ">*) the charts are not updated in "Standby mode"</div>
                    -->
					<div v-if="IsUiModeDebug" class="has-text-right">
						<template v-for="(v,k) in dataSe?.status?.devs" :key="k">
							<span 
								class="tag mr-1 is-rounded is-small"
								:class="[ v.error == 0 ? 'is-success' : 'is-danger' ]"
								:title="GetDevErrorInfo(k)">
								{{ k }}
							</span>
						</template>

						<span class="tag is-danger is-light" v-show="isSeSumError">data err</span>
						<span class="tag is-warning" v-show="getSeDataErrors > 0" title="Data err cnt">de: {{ getSeDataErrors }}</span>
						<div class="is-italic tag is-small" title="Data CM">

							[ {{ dataSe?.sum?.data?.TM || '?' }} &nbsp;{{ NowDiffSec(this.dataSe?.sum?.data?.TM) }}] 
							
							&nbsp;
							
							<i 
								title="Collector připojení (klikem obnovit)"
								@click="UserWatchingModeClick()"
								class="mdi mdi-24px" 
								:class="[ ctrlError ? 'mdi-link-off has-text-danger' : 'mdi-link' ]"
							></i>

							&nbsp;

							<i 
								title="Připojení do elektrické sítě"
								class="mdi mdi-24px" 
								:class="[ isGrid == -1 ? 'mdi-alert has-text-danger' : (isGrid == 0 ? 'mdi-power-plug-off-outline has-text-danger' : 'mdi-power-plug-outline') ]"
							></i>
						</div>
						<div class="box has-text-left is-size-6" v-if="false">
							<textarea v-model="debugConsole" style="width: 100%;" rows="10">
							</textarea>
						</div>
					</div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { store } from '../store.js'
import ApexCharts from 'apexcharts';
import { libapi } from '../libapi.js'

const DATA_INTERVAL_DEFAULT = 30

export default {
    name: 'AppDashboard',
    data : function() {
        return {
            refreshCnt : 0,
            dataInterval : DATA_INTERVAL_DEFAULT,
            uwDuration : 0,
            cntTo : null,
            chart_interval : 'HO',
            chart_value : 'g_tot_kwh',


            dataInfo : null,
            dataSe : {},
            today_kwh : 0,
            timeNow : '',
			ctrlError : false,

            chart: null,
            chartHeight: 350,
            chartLabelsEnabled: false,

			debug1 : 0,
			debugConsole : 'debug console...',

        }
    },
    computed : {
		IsUiModeDebug : function() {
			return store.IsUiModeDebug()
		},
		info : function() {
            return store.info
        },
		isErrorApp : function() {
            let err = store.error_app 
            return (err !== null && err != "")
        },
		getSeDataErrors : function() {
			if ( this.dataSe === null || Object.keys(this.dataSe).length == 0 ) {
				return 99
			}
			let errCnt = 0
			for (const k in this.dataSe) {
				const v = this.dataSe[k]
				const isObj = typeof v === 'object' && v !== null
				if ( !isObj ) errCnt++
			}
			return errCnt
		},
		isSeSumError : function() {
			if ( this.dataSe === null ) return false
			const err = this.dataSe?.sum_last?.head?.error || null
			return err !== null
		},
		curLoadW : function() {
			const em_w = this.dataSe?.sum?.data?.sum?.EM_PWR_W || 0
			const in_w = this.dataSe?.sum?.data?.sum?.ACO_W || 0
			return [new Number(em_w / 1000).toFixed(2),new Number(in_w / 1000).toFixed(2)]
			/*
			if ( em_w > 0 ) {
				return [new Number(em_w / 1000).toFixed(2),"EM",new Number(in_w / 1000).toFixed(2),"IN"]
			}
			return [new Number(in_w / 1000).toFixed(2),"IN"]
			 */
		},
		batPwr : function() {
			const chg_i = this.dataSe?.sum?.data?.sum?.BM_CHG_I || 0
			const bat_v = this.dataSe?.sum?.data?.sum?.BM_BAT_V || 0
			return chg_i * bat_v
		},
		isGrid : function() {
			const ig = this.dataSe?.sum_last?.data?.sum?.IS_GRID
			if ( ig === undefined ) return -1
			return ig
		},
    },
    methods: {
		NowDiffSec : function( stm ) {
			if ( !stm ) return "? s"
			// replace kuli iOS
			stm = stm.replace(" ","T")
			// toto nefunguje na iOS
			//const targetDate = new Date(tmData + ' UTC');
			// takto to funguje i na iOS

			let targetDate
			if ( ! stm.endsWith("Z") ) {
				targetDate = new Date(stm + 'Z');
			} else {
				targetDate = new Date(stm);
			}
			const now = new Date();
			const diffInMilliseconds = now - targetDate;
			const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
			return diffInSeconds + " sec"
		},

        GetFlag : function(flag) {
			const fl = this.dataSe?.sum_last?.data.sum[flag] || "?"
			return fl 
		},

        GetDevErrorInfo : function(dev_id) {
			// error z status
			let stat = this.dataSe?.status?.devs[dev_id] || false
			if ( !stat ) {
				return "dev_id :" + dev_id + " not found."
			}
			// driver error ze sum
			const sumErr = this.dataSe?.sum_last?.data?.devs[dev_id]?.error || ""
			if ( stat.error == 0 && sumErr === "" ) {
				return "(" + dev_id + ") " + stat.info
			}
			return "(" + dev_id + ") " + stat.info + " error: " + stat.error + " \"" + sumErr + "\""
		},
        GetNow : function() {
            let date = new Date()
            const hours = String(date.getHours()).padStart(2, '0') // HH
            const minutes = String(date.getMinutes()).padStart(2, '0') // mm
            const seconds = String(date.getSeconds()).padStart(2, '0') // ss
            const day = String(date.getDate()).padStart(2, '0') // DD
            const month = String(date.getMonth() + 1).padStart(2, '0') // MM (měsíc je 0-indexovaný)
            const year = date.getFullYear() // YYYY

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timeZoneOffset = -date.getTimezoneOffset();
            const sign = timeZoneOffset >= 0 ? '+' : '-';
            const offsetHours = String(Math.floor(Math.abs(timeZoneOffset) / 60)).padStart(2, '0');
            const offsetMinutes = String(Math.abs(timeZoneOffset) % 60).padStart(2, '0');
            const offset = `${sign}${offsetHours}${offsetMinutes}`;

            return [`${hours}:${minutes}:${seconds} ${day}.${month}.${year}`,`${offset} (${timeZone})`]
        },
        ChartBase: function( serName, data, xmin, xmax, ymin, ymax, tooltipSuffix,labelFmt, dataLabels) {
                let chartOptions = {
                    chart: {
                        // type: 'line',
                        type: 'area',
                        height: this.chartHeight,
                        animations: {
                            enabled: false,
                        },
                    },
                    dataLabels: {
                        enabled: false 
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], 
                            opacity: 0.5
                        }
                    },
                    series: [{
                        name: serName,
                        data: data
                    }],
                    xaxis: {
                        type: 'datetime',
                        min: xmin,
                        max: xmax,
                        labels: {
                            formatter: function(value) {
                                let date = new Date(value);
                                let day = String(date.getDate()).padStart(2, '0');
                                let month = String(date.getMonth() + 1).padStart(2, '0'); // Měsíce jsou indexovány od 0
                                let year = date.getFullYear();
                                let hour = date.getHours();
                                if (labelFmt == 'HO') {
                                    return `${hour}:00`
                                } else if ( labelFmt == 'MO' ) {
                                    return `${month}.${year}`;
                                } else if ( labelFmt == 'YE' ) {
                                    return `${year}`;
                                }
                                return `${day}.${month}.${year}`;
                            }
                        },
                    },
                    yaxis: {
                        //min: 0,
                        labels: {
                            formatter: function(value) {
                                if (labelFmt == 'HO') {
                                    return value 
                                }
                                return Math.round(value);
                            }
                        },
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    tooltip: {
                        y: {
                            formatter: function(value) {
                                return value + tooltipSuffix
                            }
                        }
                    },
                }
              if ( ymin !== null ) {
                  chartOptions.yaxis.min = ymin
              }
              if ( ymax !== null ) {
                  chartOptions.yaxis.max = ymax
              }
              if ( dataLabels ) {
                  chartOptions.dataLabels.enabled = true
              }
              return chartOptions
        },

        ShowChart: function() {
            const def = {
                HO : {
                    blk : 'hours',
                    labelFmt : 'HO',
                    minusStep : function(tm) {
                        tm.setHours(tm.getHours()-1)
                        return tm
                    },
                    plusStep : function(tm) {
                        tm.setHours(tm.getHours()+1)
                        return tm
                    }
                },
                DA : {
                    blk : 'days',
                    labelFmt : 'DT',
                    minusStep : function(tm) {
                        tm.setHours(tm.getHours()-24)
                        return tm
                    },
                    plusStep : function(tm) {
                        tm.setHours(tm.getHours()+24)
                        return tm
                    }
                },
                MO : {
                    blk : 'months',
                    labelFmt : 'MO',
                    minusStep : function(tm) {
                        const currentMonth = tm.getMonth();
                        if (currentMonth === 0) {
                            tm.setMonth(11)
                            tm.setFullYear(tm.getFullYear() - 1)
                        } else {
                            tm.setMonth(currentMonth - 1)
                        }
                        return tm;
                    },
                    plusStep : function(tm) {
                        const currentMonth = tm.getMonth();
                        if (currentMonth === 11) {
                            tm.setMonth(0)
                            tm.setFullYear(tm.getFullYear() + 1)
                        } else {
                            tm.setMonth(currentMonth + 1)
                        }
                        return tm;
                    },
                },
                YE : {
                    blk : 'years',
                    labelFmt : 'YE',
                    minusStep : function(tm) {
                        tm.setFullYear(tm.getFullYear() - 1)
                        return tm;
                    },
                    plusStep : function(tm) {
                        tm.setFullYear(tm.getFullYear() + 1)
                        return tm;
                    },
                },
            }

            if ( this.dataSe === null ) return

            let dd = def[this.chart_interval]
            if ( dd === null ) {
                console.log("Invalid chart_interval: ", this.chart_interval)
                return
            }

            let g1 = this.dataSe[this.chart_value][dd.blk]
            const tm0 = new Date(g1.tm0)
            let tm = new Date(tm0)
            let gdata = []
            let chartOptions = null

            // pokud hodinovy graf, tak zkontroluj zda data nejsou starsi nez 1den
            // kdyz jsou starsi nezobrazuj nic
            function isDataOk(me) {
                if ( me.chart_interval == 'HO' ) {
                    const diff =  (new Date().getTime() - tm0.getTime()) / 1000
                    if ( diff > 60*60*24 ) {
                        return false
                    }
                }
                return true
            }


            // graf AVG
            if ( g1.dtype === 'AVG' ) {

                for ( let i = 0; i < g1.blk.length; i++ ) {
                    let v = 0
                    let c = g1.blk[i].c
                    if ( c > 0 ) {
                        v = g1.blk[i].v / c
                        let it = { x : tm.getTime(), y : v.toFixed(2) }
                        gdata.push(it)
                    }
                    tm = dd.minusStep(tm)
                }


                const xmin = tm.getTime()
                const xmax = dd.plusStep(new Date()).getTime()
                const ymin = 0
                const ymax = 100
                const tooltipSuffix = ' %'
                const labelFmt = dd.labelFmt
                const dataLabels = this.chartLabelsEnabled
                if ( !isDataOk(this) ) {
                    gdata = []
                }
                chartOptions = this.ChartBase( '', gdata, xmin, xmax, ymin, ymax, tooltipSuffix, labelFmt, dataLabels )

            // graf INCR
            } else if ( g1.dtype === 'INCR' ) {

                let vMax = 0
                for ( let i = 0; i < g1.blk.length; i++ ) {
                    let v = new Number(g1.blk[i].V - g1.blk[i].v)
					/*
					if ( v < 0 ) { // TODO: nekdy tu vyjde minus kuli dopocitavani desetinnych mist u TOT_KWH
						v = new Number(0.07)
					}
					 */
                    if ( v > vMax ) vMax = v
                    let it = { x : tm.getTime(), y : v.toFixed(2) }
                    gdata.push(it)
                    tm = dd.minusStep(tm)
                }

                const xmin = tm.getTime()
                const xmax = dd.plusStep(new Date()).getTime()
                const ymin = 0
                let ymax = Math.ceil(vMax)
				ymax = ymax + Math.ceil(ymax * 0.1)
                const tooltipSuffix = ' kWH'
                const labelFmt = dd.labelFmt
                const dataLabels = this.chartLabelsEnabled
                if ( !isDataOk(this) ) {
                    gdata = []
                    ymax = 10
				}
                chartOptions = this.ChartBase( '', gdata, xmin, xmax, ymin, ymax, tooltipSuffix, labelFmt, dataLabels )
            } else {
                console.log("Error - invalid type: ",g1.type)
                return 
            }

            if ( this.chart !== null ) {
                this.chart.destroy()
            }
            this.chart = new ApexCharts(this.$refs.chart, chartOptions)
            this.chart.render()

        },
        ChLabelsEnabledChange: function() {
            this.ShowChart()
        },
        ChValClick: function( t ) {
            this.chart_value = t
            this.ShowChart()
        },
        ChIntClick: function( t ) {
            this.chart_interval = t
            this.ShowChart()
        },
		DebugClick: function() {
			this.debug1++
			if ( this.debug1 == 5 ) {
				store.info.ui_mode = 99
			}
		},
		DebugConAppend: function( msg ) {
			this.debugConsole = this.debugConsole + "\n" + msg
		},
		SetDataInterval: function( interval ) {
			this.dataInterval = interval
			this.refreshCnt = interval
			if ( interval === DATA_INTERVAL_DEFAULT ) {
				// pokud default interval nestavime nejake vyssi duration
				this.uwDuration = interval * 10
			}
		},
        UserWatchingModeClick: function() {
			this.ApiData()
			this.ctrlError = false
            libapi.Ctrl({ user_watching : 1}).then(
                (resp) => {
					/*
                if ( libapi.HandleEEError(resp) ) {
					this.ctrlError = true
                    return
					}
					 */
					if ( libapi.IsEEError(resp)) {
						this.ctrlError = true
						this.uwDuration = 0
						this.SetDataInterval(DATA_INTERVAL_DEFAULT)
					} else {
						this.uwDuration = resp?.data?.duration || 0
						this.SetDataInterval(resp?.data?.interval || DATA_INTERVAL_DEFAULT)
					}
                this.RefreshCntTimer()
            })

        },
        RefreshCntTimer: function() {
            this.timeNow = this.GetNow()
            if ( this.refreshCnt  > 0 ) {
                this.refreshCnt--
                this.uwDuration--
                if ( this.uwDuration < 1 ) {
					this.SetDataInterval(DATA_INTERVAL_DEFAULT)
                }
            } else {
                this.refreshCnt = 0
                this.ApiData()
            }
            clearTimeout(this.cntTo)
            this.cntTo = setTimeout(this.RefreshCntTimer, 1000)
            if ( this.dataSe == null ) {
                this.ApiData()
            }
        },
        ApiData: function() {
			// pokud je error tak nastavime default interval
			// jinak se nenastavi zadny a data se pri 403 stale obnovuji
			if ( this.isErrorApp ) {
				this.SetDataInterval(DATA_INTERVAL_DEFAULT)
			}
            libapi.Data().then(
                (resp) => {
                    if ( libapi.IsEEError(resp) ) {
						this.dataSe = null
						this.SetDataInterval(DATA_INTERVAL_DEFAULT)
                        return
                    }
                    this.dataSe = resp.data
					if ( Object.keys(this.dataSe).length === 0 ) {
						this.SetDataInterval(DATA_INTERVAL_DEFAULT)
                        return
					}

                    // vypocet kw vyrobenych za dnesni den
					const blk = this.dataSe?.g_tot_kwh?.days || false
					this.today_kwh = 0
					if ( blk !== false ) {
						const v1 = blk?.blk[0]?.v
						const v2 = blk?.blk[0]?.V
						this.today_kwh = v2 - v1
					}
					this.ShowChart()

                    this.refreshCnt = this.dataInterval
                })
        },

        HandleVisibilityChange() {
            if (document.hidden) {
                clearTimeout(this.cntTo)
            } else {
                this.UserWatchingModeClick()
            }
        },
    },

    mounted: function() {
        this.UserWatchingModeClick()
        document.addEventListener('visibilitychange', this.HandleVisibilityChange)
    },
	beforeUnmount: function() {
        clearTimeout(this.cntTo)
        if (this.chart) {
            this.chart.destroy()
        }
        document.removeEventListener('visibilitychange', this.HandleVisibilityChange)
	},
}
</script>

<style>
    .my-td-vam {
        vertical-align:middle !important;
    }
	.my-line-h1 {
		line-height: 1
	}
</style>
