<template>

    <div class="box" >
    <div class="is-size-3">Settings</div>

		<div class="is-size-4">Network settings</div>
        <div class="field">
            <label class="label has-text-left">IP address</label>
            <div class="control">
                <input class="input"
                    :class="{ 'is-danger': validatorErrors['address'] }" 
                    v-model="address"
                    type="text"
                    placeholder="IP address">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['address'] }}</div>
        </div>
        <div class="field">
            <label class="label has-text-left">Netmask</label>
            <div class="control">
                <input class="input" 
                    :class="{ 'is-danger': validatorErrors['netmask'] }" 
                    v-model="netmask"
                    type="text"
                    placeholder="Netmask">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['netmask'] }}</div>
        </div>
        <div class="field">
            <label class="label has-text-left">Default gateway</label>
            <div class="control">
                <input class="input" 
                    :class="{ 'is-danger': validatorErrors['gw'] }" 
                    v-model="gw" 
                    type="text" 
                    placeholder="Default gateway">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['gw'] }}</div>
        </div>
		<div class="help has-text-left">*) network settings changes will take effect after a reboot</div>
		<br/>
        <div class="field">
            <label class="label has-text-left">API Auth Token</label>
            <div class="control">
                <input class="input" 
                    v-model="token" 
                    type="text" 
                    maxlength="20"
                    placeholder="API Auth Token">
            </div>
        </div>

    <br/>
    <br/>

		<div class="is-size-4">Electrometer connection</div>
        <div class="field">
            <label class="label has-text-left">TCP link IP address</label>
            <div class="control">
                <input class="input"
                    :class="{ 'is-danger': validatorErrors['link_tcp_address'] }" 
                    v-model="link_tcp_address"
                    type="text"
                    placeholder="TCP link IP address">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['link_tcp_address'] }}</div>
        </div>
        <div class="field">
            <label class="label has-text-left">TCP link port</label>
            <div class="control">
                <input class="input"
                    :class="{ 'is-danger': validatorErrors['link_tcp_port'] }" 
                    v-model="link_tcp_port"
                    type="text"
                    placeholder="TCP link port">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['link_tcp_port'] }}</div>
        </div>
        <div class="field">
            <label class="label has-text-left">Serial link id</label>
            <div class="control">
                <input class="input"
                    :class="{ 'is-danger': validatorErrors['link_serial_id'] }" 
                    v-model="link_serial_id"
                    type="text"
                    placeholder="Serial link id">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['link_serial_id'] }}</div>
            <div class="help has-text-left">*) If serial id is filled, TCP link is ignored. </div>
        </div>



    <br/>

        <div class="field">
            <div class="control has-text-right">
                <button class="button is-info" @click="SaveSettingsClick()">Save</button>
            </div>
        </div>
    </div>

    <div class="box" >
		<div class="is-size-3">Change password</div>

        <div class="field">
            <div class="control">
                <input class="input" 
                    :class="{ 'is-danger': validatorErrors['passwd1'] }" 
                    v-model="passwd1" 
                    type="password" 
                    maxlength="29"
                    placeholder="Password 1">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['passwd1'] }}</div>
        </div>
        <div class="field">
            <div class="control">
                <input class="input" 
                    :class="{ 'is-danger': validatorErrors['passwd2'] }" 
                    v-model="passwd2" 
                    type="password" 
                    maxlength="29"
                    placeholder="Password 2">
            </div>
            <div class="help is-danger has-text-left">{{ validatorErrors['passwd2'] }}</div>
        </div>
        <div class="field">
            <div class="control has-text-right">
                <button class="button is-info" @click="ChangePassword()">Save</button>
            </div>
        </div>

	</div>


    <div class="box" >
    <div class="is-size-3">Firmware update</div>
        <FileChunked/>
    </div>

    <div class="box" >
			<button class="button is-info" @click="Reboot()">Reboot</button>
	</div>
	<br/>
	<br/>

</template>

<script>
    import { util } from '../util.js'
    import { libapi } from '../libapi.js'
    import FileChunked from './FileChunked.vue'

    export default {
        components: {
            FileChunked,
        },
        data : function() {
            return {
				validatorErrors : {},

                address : "",
                netmask : "",
                gw : "",
                token : "",
                passwd1 : "",
                passwd2 : "",
				link_tcp_address : "",
				link_tcp_port : "",
				link_serial_id : "",
            }
        },
		methods: {
            GetSettings: function() {
                util.AxiosGetByRouteName("settings").then((response) => {
                    var resp = response.data
                    this.address = resp.data.address
                    this.netmask = resp.data.netmask
                    this.gw = resp.data.gw
                    this.token = resp.data.token
                    this.link_tcp_address = resp.data.link_tcp_address
                    this.link_tcp_port = resp.data.link_tcp_port
                    this.link_serial_id = resp.data.link_serial_id
                })
            },
            SaveSettingsClick: function() {
				this.validatorErrors = {}
                const pars = new URLSearchParams();
                pars.append('address', this.address.trim());
                pars.append('netmask', this.netmask.trim());
                pars.append('gw', this.gw.trim());
                pars.append('token', this.token.trim());
                pars.append('link_tcp_address', this.link_tcp_address.trim());
                pars.append('link_tcp_port', this.link_tcp_port.trim());
                pars.append('link_serial_id', this.link_serial_id.trim());
                var opt = {
                    onOk : () => {
                        this.processAllInProgress = false
                        util.ShowToastOk( "Saved successfully." )
                    },
                    onErrorValidate : (err) => {
                        console.log("err:" , err);
                        var el = err.error_list
                        if ( el !== null ) {
                            this.validatorErrors = el
						}
                    },
                }
                libapi.SaveSettings( pars, opt )
            },

            ChangePassword: function() {
				this.validatorErrors = {}
                const pars = new URLSearchParams();
                pars.append('passwd1', this.passwd1.trim());
                pars.append('passwd2', this.passwd2.trim());
                var opt = {
                    onOk : () => {
                        this.processAllInProgress = false
                        util.ShowToastOk( "Password changed successfully." )
                    },
                    onErrorValidate : (err) => {
                        console.log("err:" , err);
                        var el = err.error_list
                        if ( el !== null ) {
                            this.validatorErrors = el
						}
                    },
                }
                libapi.ChangePassword( pars, opt )
            },

            Reboot: function() {
				var ret = confirm("Reboot ?");
				if ( ret ) {
					libapi.Reboot()
				}
			},
		},
		mounted: function() {
            this.GetSettings()
		}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


