<template>
    <div class="hero">
        <div class="hero-body">

            <div v-show="error_app !== ''">
                <br/>
                <div class="is-danger tag is-medium">{{ error_app }}</div>
                <br/>
            </div>

            <div class="columns is-centered">
                <div class="column is-one-third">
                    <div class="is-size-3"></div>
                    <div class="field">
                        <label class="label has-text-left">Uživatel</label>
                        <div class="control">
                            <input class="input" v-model="user" type="text" placeholder="" @keyup="LowerCaseUser()">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left">Heslo</label>
                        <div class="control">
                            <input class="input" v-model="passwd" type="password" placeholder="">
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-info" @click="LoginClick()">Přihlášení</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { libapi } from '../libapi.js'
    import { config } from '../config.js'
    import { util } from '../util.js'
    import { store } from '../store.js'

    export default {
        data : function() {
            return {
                user : "",
                passwd : "",
            }
        },
        computed : {
            error_app : function() {
                return store.error_app
            },
        },
		methods: {
            LoginClick : function() {
                store.ErrorAppClear()
                libapi.Login( this.user, this.passwd ).then(
                    (resp) => {
                        if ( resp == libapi.OK ) {
                            libapi.Info().then(
                                () => {
                                    this.$router.push({ name: 'AppDashboard'}).catch(()=>{});
                                }
                            )
                        } else {
                            util.ShowToastErr("Neplatné přihlašovací údaje")
                        }
                    })
            },
            LowerCaseUser : function() {
                this.user = this.user.toLowerCase()
            }
		},
		mounted: function() {
            if ( config.IsDevel() ) {
                this.user = config.DEVEL_DATA.login
                this.passwd = config.DEVEL_DATA.password
                this.LoginClick()
            }
		}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


